.header {
  position: sticky;
  top: -20px;
  z-index: 2;
  display: block;
  padding: 20px 0;
  background-color: #fff;
  color: #333;
  font-family: "Lato",sans-serif;
  border-bottom: none;
  margin: 0 auto 1em;
}

.header::before{
  content:'';
  display:block;
  height:1px;
  width: 100vw;
  background:#ebebeb;
  z-index:1;
}

.header a, .links-dropdown {
  color: #4e4e4e;
  text-decoration: none;
  text-transform: uppercase;
  padding: 2em 1em 0;
  font-style: normal;
  letter-spacing: 0.085em;
  font-size: 16px; 
  font-weight: bold;
  position: relative;
  display: inline-flex;
}

.header a::before, .links-dropdown::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -3px; 
  height: 3px;
  background-color: transparent; 
  transition: background-color 0.3s; 
}

.header a:hover::before, .header a:focus::before, .links-dropdown:hover::before, .links-dropdown:focus::before {
  background-color: #cb202d; 
}

.header a.active::before, .links-dropdown.active::before {
  background-color: #cb202d; 
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -3px; 
  height: 3px;
  transition: background-color 0.3s; 
}

.header a.active {
  color: #aeaeae; 
}

.links-dropdown {
  display: inline;
  position: relative; 
  cursor: pointer; 
}

.dropdown-content {
  display: none; 
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f9f9f9; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  z-index: 1; 
  width: max-content;
}

.links-dropdown:hover .dropdown-content {
  display: block; 
}

.dropdown-content a {
  color: black; 
  padding: 12px 0; 
  text-decoration: none; 
  display: block; 
  width: 100%;
  margin: auto;
}

.dropdown-content a:hover {
  background-color: #f1f1f1; 
}

.dropdown-content .links-dropdown {
  display: block;
  width: 100%;
  padding: 1em 0;
}

.dropdown-content .links-dropdown .dropdown-content {
  transform: translate(-155px, -100px);
  width: 100%;
}

.dropdown-content .links-dropdown .dropdown-content a:hover::before,
.dropdown-content .links-dropdown .dropdown-content a:focus::before,
.dropdown-content .links-dropdown .dropdown-content a.active::before,
.dropdown-content .links-dropdown:hover::before,
.dropdown-content a:hover::before {
  background-color: transparent !important;
}

/* Mobile Styles */
@media (max-width: 990px) {
  .header {
    justify-content: space-between;
    padding: 10px;
  }

  .header a.active::before, .links-dropdown.active::before, .header a:hover::before, .header a:focus::before, .links-dropdown:hover::before, .links-dropdown:focus::before {
    display: none;
  }

  .nav-links { 
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-wrap: pretty;
  }

  .nav-links.active { 
    display: flex;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    width: 35px;
    margin: auto;
  }

  .hamburger div {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }

  
  .hamburger.active .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .hamburger.active .bar2 {opacity: 0;}

  .hamburger.active .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
