.welcome-section {
  text-align: left;
  padding: 3em;
  display: flex;
  max-width: 80vw;
  margin: auto;
}

.welcome-content {
  margin-right: 4em;
}

.welcome-section {  
  line-height: 1.7;
}

/* Mobile Styles */
@media only screen and (max-width: 990px) {
  .welcome-section {
    flex-direction: column;
    text-align: center;
    padding: 1em;
  }
  
  .welcome-content {
    margin-right: 0;
  }
  
  .welcome-section * {
    padding: 0;
  }
  
  .welcome-section h1 {
    font-size: 1.8em;
  }
  
  .welcome-section h2 {
    font-size: 1.6em;
  }
  
  .welcome-section h3 {
    font-size: 1.4em;
  }
  
  .welcome-section p {
    font-size: 1.2em;
  }
  
  .welcome-section ul {
    font-size: 1.2em;
  }
  
  .welcome-section ul li {
    padding-left: 0;
    margin-bottom: 5px;
  }
  
  .welcome-section ul li::before {
    content: '•';
    padding-right: 5px;
  }
  
  .welcome-section img {
    width: 100%;
  }
}