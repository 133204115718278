.services-section {
  text-align: center;
  padding: 50px 20px;
  background-image: url('../../public/modern_neighborhood.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff; 
}

.service {
  margin-bottom: 30px; 
  background: none; 
  padding: 0; 
  display: inline-grid;
}

.service h3 {
  font-size: 28px; 
  color: #fff; 
  margin-bottom: 15px; 
}

.service p {
  font-size: 18px; 
  margin: 0 20%; 
  line-height: 1.6; 
}

.service a {
  color: #fff; 
  text-decoration: none; 
  font-size: 25px; 
  padding: 10px 20px; 
  transition: all 0.3s; 
}

.service a:hover {
  background: #9393938b; 
}

.services-title {
  margin-bottom: 30px; 
  font-size: 38px; 
  color: #fff;
  text-align: center;
  margin: 0 0 1em 0;
}

.services-subtitle {
  width: 60%;
  margin: auto;
  line-height: 1.6;
}

.just-ask {
  text-decoration: none;
  transition: all 0.3s;
}

.just-ask:hover {
  text-decoration: underline;
  color: #fff;
}