.footer {
  background-color: white;
  color: black;
  height: 500px;
  width: 100vw;
  display: table-cell;
  vertical-align: bottom;
  border-top: 1px solid #ebebeb;
  text-align: left;
}

.footer-content {
  display: flex;
  justify-content: space-around;
}

.footer-section {
  display: flex;
  flex-direction: column;
  width: 30vw;
  justify-content: center;
  margin-bottom: 2em;
}

.footer-content:first-child {
  padding-left: 5.5em;
}

.footer-title {
  color: #961f1f;
  font-size: 20pt;
}

.footer-text {
  line-height: 1.9;
  text-wrap: wrap;
}

.footer-links {
  list-style: none;
  padding: 0;
  line-height: 2;
}

.footer-links a {
  color: black;
  text-decoration: none;
}

.footer-links a:hover {
  color: #961f1f;
}

.footer-links li {
  margin-bottom: 5px;
  border-block-start: 2px solid #ebebeb;
}

.footer-bottom {
  border-top: 1px solid #ebebeb;
  text-align: center;
  padding-top: 10px;
}

.affiliate-logo {
  width: 100px;
  margin-bottom: 10px;
}

.footer-content * {
  padding-inline: 1em;
}

/* Mobile Styles */
@media only screen and (max-width: 990px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    width: 100%;
    text-align: center;
  }

  .footer-content:first-child {
    padding-left: 0;
    width: 80%;
    margin: auto;
  }
}