
.not-found {
    text-align: center;
    padding: 50px;
  }
  
  .not-found h1 {
    font-size: 100px;
    margin-top: 0;
  }
  
  .not-found p {
    font-size: 20px;
  }
  
  .not-found a {
    color: blue;
    text-decoration: none;
  }
  