.hero-section {
  width: 100%;
  margin: 0 auto;
}

.slick-slide {
  position: relative;
  overflow: hidden; 
  max-height: 600px;
  color: white;
}


.slick-slide img {
  transition: transform .5s ease;
  animation: zoom 20s infinite; 
  width: 100%;
  height: 600px; 
  object-fit: cover;
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); 
  }
}

.slide-content {
  position: absolute;
  bottom: 25%; 
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 600px;
}

.slick-slide h2 {
  font-size: 2em;
  padding: 0.5em 0.5em 0 0.5em;
  text-transform: uppercase;
  margin: 0;
  background: #ffffffd4;
}

.slick-slide p {
  background: #ffffffd4;
  padding: 1em;
  margin: 0;
  color: #961f1fd4;
}

button {
  background-color: #961f1fd4; 
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1em;
  border: solid 3px white;
  transition: background-color 0.3s;
  text-transform: uppercase;
}

button:hover {
  background-color: #ffffffd4;
  color: #961f1fd4;
  border: solid 3px #961f1fd4;
}

.slick-prev,
.slick-next {
  color: #fff;
  font-size: 0;
  opacity: 0.8;
  background: transparent;
  border: none;
  border-radius: 50%;
}

.slick-prev { left: 10px; z-index: 1; }
.slick-next { right: 10px; z-index: 1; }

.slick-prev:hover, .slick-next:hover {
  opacity: 1;
  color: transparent;
  border: none;
  background-color: transparent;
}

.slick-dots {
  bottom: 10px;
  text-align: center;
}

.slick-dots li button:before {
  font-size: 12px;
  color: white;
}

.slick-dots li.slick-active button:before {
  color: #961f1f;
}
