.home-wise-docs-section {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  border-radius: 10px;
  width: fit-content;
  margin: auto;
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 10px;
}
.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.content p {
  color: #333333;
  margin-bottom: 15px;
  line-height: 1.6;
}

.content a {
  color: #1a0dab; 
  text-decoration: none;
}

.content a:hover {
  text-decoration: underline;
}

.content ul {
  list-style-type: disc;
  padding-left: 40px;
}

.content li {
  margin-bottom: 5px;
}

.content strong {
  font-weight: bold;
}

.content .logo {
  width: 200px; 
  display: block;
  margin: 0 auto 20px;
}

@media screen and (max-width: 768px) {
  .content {
    padding: 10px;
  }

  .content p, .content ul, .content li {
    font-size: 14px;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 990px) {
  .home-wise-docs-section,
  .home-wise-docs-section .content,
  .home-wise-docs-section img {
    width: 90%;
    margin: auto;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .content {
    padding: 20px 10px;
  }
}