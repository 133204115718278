html {
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
  font-family: "Arial", sans-serif;
}

.card {
  display: inline-block;
  margin: 0 auto 10px;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  width: 300px;
  max-width: 100%;
  vertical-align: top;
  box-shadow: 0 0 5px #ccc;
  width: 65%;
}

.card img {
  float: right;
  width: 30%;
}

.card h1 {
  font-size: 1.8em;
  margin-top: 0;
  margin-bottom: 20px;
}

.card h2 {
  font-size: 1.6em;
  margin-top: 20px;
  margin-bottom: 10px;
  text-wrap: pretty;
}

.card h3 {
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.card p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.card ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.card ul li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
}

.card ul li::before {
  content: '•';
  color: #333; 
  position: absolute;
  left: 0;
  top: 0;
}

.card blockquote {
  border-left: 3px solid #333; 
  padding-left: 15px;
  font-style: italic;
  margin: 20px 0;
}

.btn {
  display: inline-flex;
  margin: 10px auto;
  padding: 0.75em;
  text-decoration: none;
  color: white;
  background-color: #10a7ff;
  border: 1px;
  border-radius: 5px;
  font-size: 12pt;
  cursor: pointer;
}

.btn:hover {
  background-color: #0c8de4;
}

.btn-cancel {
  background-color: #ff4d4d;
  margin-left: 10px;
}

.btn-cancel:hover {
  background-color: #e63939;
}

.column {
  width: fit-content;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.title-with-logo {
  display: inline-flex;
  align-items: center;
}

.title-text {
  margin-left: 10px;
}

.captcha-wrapper {
  display: flex;
  margin-top: 1em;
  flex-direction: column;
  align-items: center;
}

input, textarea, select {
  margin: 10px 0.5em;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12pt;
  font-family: sourcecode-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  width: 85%;
}

select {
  width: 88% !important;
}

textarea {
  height: 100px;
  width: 85%;
}

.main-logo {
  width: 300px;
  height: 205px;
  margin-top: 1em;
}

.italic {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a, blockquote {
  color: #902020;
}

h3, .h3 {
  letter-spacing: -0.035em;
  font-size: 228.5%;
  line-height: 1.3;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "PT Sans",sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 1.25em 0 .2em;
  text-rendering: optimizelegibility;
}

hr {
  border: 0;
  height: 1px;
  background: #ebebeb;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

form h1 {
  text-wrap: pretty;
}

.top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border: none;
  outline: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}

.top-btn:hover {
  background-color: #f3f3f3;
}

/* Mobile Styles */
@media only screen and (max-width: 990px) {
  html, body, .App {
    width: 100%;
    overflow-x: hidden;
  }

  .card img {
    display: none;
  }

  .card{
    display: block !important;
    width: 90% !important;
    margin: 0 auto 10px;
    padding: 2em;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    box-shadow: 0 0 5px #ccc;
  }

  .card .container {
    display: block !important;
  }

  .card p, iframe {
    width: 90% !important;
  }

  .card form {
    width: 90%;
    margin: 0;
    padding-top: 1em;
  }
}